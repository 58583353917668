import { Row, Col } from 'antd';
import ExternalLink from 'components/shared/ExternalLink';
import { ReactNode, useState } from 'react';

export default function PrivacyPolicy() {
  const [header, setHeader] = useState<ReactNode>('Privacy Policy');

  const stripePolicy = 'https://stripe.com/privacy';

  const getHeader = () => {
    const arr = [
      'Yaaaaaaaaannnnnnnnnnnn',
      '😴💤💤💤💤💤💤💤💤💤💤',
      'Boooooooooooooooorrrring',
      "You're not even going to read this are you?",
      <ExternalLink
        cssClass="rofl"
        url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        text="I wonder where this goes..."
      />,
    ];

    return arr[Math.floor(Math.random() * arr.length)];
  };

  const handleHeaderChange = () => setHeader(getHeader());

  return (
    <section className="page content privacy-policy">
      <h1 className="clickable" onClick={handleHeaderChange}>
        {header}
      </h1>

      <h2>Our Site</h2>
      <Row>
        <Col xl={18} sm={24}>
          <p>
            We don't collect any personal data besides your email address. Entering your email is
            optional (unless you're creating a group subscription). Adding an email however is recommended in order
            to reset your password should you forget it. We don't sell any of you data.
          </p>
          <p>
            If you're subscribing to a group plan you're asked for your full name. We don't save
            this data, but it is passed and saved on Stripe and used for identifications purposes
            and in order to charge your card.
          </p>
        </Col>
      </Row>

      <h2>Stripe</h2>
      <Row>
        <Col xl={18} xs={24}>
          <p>
            We use Stripe to handle group subscription payments. Stripe collects and processes personal
            data... blah blah blah. See Stripe's privacy policy for yourself here:{' '}
            <ExternalLink url={stripePolicy} text={stripePolicy} />.
          </p>
        </Col>
      </Row>
    </section>
  );
}
